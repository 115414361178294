.notMobile {
  display: unset!important;
}
.inMobile {
  display: none!important;
}
.paragraph {
  margin-top: 0!important;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.custom-control-label {
  cursor: pointer;
}
table.max-content-always {
  width: max-content!important;
}
.fixe-height-modal > .modal-content {
  max-height: 48em;
  min-height: 40em;
}
.subtable:hover {
  background-color: transparent!important;
}
.subtable > td {
  background-color: rgba(233,236,239,.5)!important;
}
table.fixed thead th {
  position: sticky;
  z-index: 1;
  background: white;
}
table.fixed td {
  background: #fff;
  padding: 4px 5px;
}
table.fixed thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.fixed tbody td:first-child, table.fixed tbody th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table.fixed tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
table.fixed tbody td {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
  min-width: 100px;
}
.btn-opacity {
  opacity: .7;
}

.btn-opacity:hover{
  opacity: 1;
}

.app-footer ~ .rcw-widget-container, .has-footer ~ .rcw-widget-container {
  bottom: 60px;
}

.app-footer ~ .whatsapp-widget-container, .has-footer ~ .whatsapp-widget-container {
  bottom: 140px !important;
}

.app-footer ~ .rcw-widget-container > .rcw-conversation-container, .has-footer ~ .rcw-widget-container > .rcw-conversation-container {
  bottom: -60px;
  left: -70px;
}

.rcw-conversation-container > .rcw-header, .rcw-launcher, .rcw-widget-container .loader-container, .rcw-widget-container .rcw-close-button {
  background-color: var(--primary);
}

.rcw-message > .rcw-response > .rcw-message-text {
  background-color: var(--primary);
  color: white;
  border-end-start-radius: 0;
}

.rcw-widget-container .loader-container {
  border-end-start-radius: 0;
}

.rcw-message > .rcw-response > .rcw-message-text * {
  color: white!important;
}

.rcw-widget-container .loader-container * {
  color: white;
  background-color: white;
}

.rcw-client > .rcw-message-text {
  background-color: var(--secondary);
  color: white;
  border-end-end-radius: 0;
}

.rcw-widget-container .loader-container {
  width: auto;
  height: auto;
}

.rcw-widget-container .quick-button {
  color: var(--primary);
  border-color: var(--primary);
}

.rcw-widget-container .quick-button:active {
  background-color: var(--primary);
}

@media (max-width: 992px) {
  .notMobile {
    display: none!important;
  }
  .inMobile {
    display: unset!important;
  }
  ul#navAdaptive {
    display: grid!important;
  }
  table.max-content {
    width: max-content!important;
  }
  .fixe-height-modal > .modal-content {
    max-height: 45em;
    min-height: 40em;
  }
}

@media(max-width: 800px) {
  .rcw-conversation-container {
    top: 0!important;
    left: 0!important;
    bottom: 0!important;
    right: 0!important;
    margin: auto!important;
    max-height: 80vh!important;
  }

  .app-footer ~ .rcw-widget-container {
    bottom: 0;
  }

  .app-footer ~ .rcw-widget-container .rcw-launcher {
    bottom: 60px;
  }
}
